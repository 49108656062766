body {
    /* background-color: #1F1F1F; */
    background-color: #000000;
    font-family: "Roboto";
}

.Mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.Mobile-dev {
    position: absolute;
    z-index: 1;
}

.back-mobile {
    /* position: absolute; */
    /* height: 200%; */
    z-index: 0;
}

/* TITLE */

.title-mobile {
    margin-left: 10%;
    margin-top: 3%;
    font-size: 2.5em;
    font-weight: 100;
    color: white;
    letter-spacing: 2px;
}

.cursor {
    font-size: 0.75em;
    letter-spacing: 2px;
}

.title-line {
    position: relative;
    top: -15px;
    width:30%;
    margin-left:8%;
    border-top: solid 1px #C5B358;
}

/* TOP CARD */

/* .top-card{
    height: 100%;
} */

.top-subtitles {
    margin-top: 6%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subtitles {
    /* flex-direction: column;
    justify-content: space-around;
    width: 50vw; */
    text-align: left;
    line-height: 1.6;
    list-style: none;
    padding: 5% 5% 5% 15%;
    font-weight: 400;
    letter-spacing: 1.5px;
    /* padding: 0 0 8px 10px;
    display: inline; */
}

.logoSVG {
    padding: 0 22% 0 10%;
    width: 50vw;
}

.IconIcon {
    /* width: 50%; */
}

.question {
    font-size: 1.5em;
    justify-content: space-between;
}

.questions-gold {
    font-weight: 300;
    color: #C5B358;
}

.Enquire-top {
    width: 100%;
    padding-top: 1%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* FEATURES */

.features {
    background-color: #fafafa;
    box-shadow: 10px 10px 70px 10px rgba(255, 255, 255, 0.4);
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9% 5% 0 5%;
    border-radius: 10px;
}

.feature-table {
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.feature-card {
    /* width: 50%; */
    flex-direction: column;
    vertical-align: top;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* padding: 5% 20% 5% 20%; */
}

.feature-title {
    font-weight: 300;
}

.feature-content {
    padding: 0% 10% 0% 10%;
    line-height: 1.7em;
    font-size: 1.2em;
}

.feature-card a {
    color: #C5B358;
    font-weight: 500;
}

/* CONTACT */

.contact-card {
    padding: 2%;
}

.popup-content {
    border-radius: 6px;
    width: 90%;
    /* max-width: fit-content; */
}

.contact-us {
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    /* color: white; */
    text-align: left;
    padding: 0 17% 1% 1%;
    margin: 0 0 6% 0;
    font-weight:100;
}

.button-send {
    text-align: right;
}

.contact-card > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 6px 8px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 28px;
    background: #ffffff;
    border-radius: 25px;
    border: 1px solid #cfcece;
    box-shadow: 0px 2px 10px 0px rgba(134, 134, 134, 0.356)
  }

/* BACKGROUND */

/* .ariere-mobile {
    position: relative;
    height: 70%;
  } */

  .circles{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 85%;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border-bottom: solid 1px #2f2f2f;
  }

  .circles li{
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.418);
      animation: animate 21s linear infinite;
      bottom: -150px;
      
  }

  .circles li:nth-child(1){
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
  }


  .circles li:nth-child(2){
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 0.5s;
      animation-duration: 10s;
  }

  .circles li:nth-child(3){
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 1.1s;
  }

  .circles li:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 6s;
  }

  .circles li:nth-child(5){
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
  }

  .circles li:nth-child(6){
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 1.8s;
  }

  .circles li:nth-child(7){
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
  }

  .circles li:nth-child(8){
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 7s;
      animation-duration: 20s;
  }

  .circles li:nth-child(9){
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 1s;
      animation-duration: 10s;
  }

  .circles li:nth-child(10){
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 12s;
  }

  @keyframes animate {

      0%{
          transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }

      100%{
          transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }

  }