@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  /* height:fit-content; */
  /* position:absolute; */
}



body {
    /* background-color: #1F1F1F; */
    background-color: #141414;
    font-family: "Roboto";
}

.particles {
  /* position: absolute; */
  /* left: -100px; */
  /* width: 100%;
  height: 90%; */
  z-index: -1;
}

.Top-home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    margin: 17vh 0 0 0;
    width: 300px;
}

.header {
    color: white;
    margin:100px 0 31vh 0;
    font-weight: 100;
    font-size: 40px;  
}

.business {
    color:#C5B358;
    /* font-weight: 300; */
    /* color: #424242 */
}

.solutions {
    width: 100%;
    box-shadow: inset 0 7px 60px -20px rgba(0,0,0,0.4);
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
}


/* ARROW */

.ImgArrow {
    width: 150px;
}

.arrow Link {
    color: white; text-decoration: none;
    width: 20px;
}

.arrow {
    text-align: center;
    margin: 3% 0;
    color: white;
  }
  .bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }

  /* MISSION */

  .Mission {
      position: relative;
      top: -200px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin: 2% 13%;
      display: flex;
      flex-direction: row;
      background-color: #fdfdfd;
      padding: 0 0 0 20px;
      border-radius: 2px;
      box-shadow: 15px 15px 35px rgba(0,0,0,0.4);
      /* border: solid black; */
  }

  .Mission-content {
      flex-direction: column;
      /* justify-content: space-around; */
      width: 50%;
      text-align: left;
      /* line-height: 1.6; */
      padding: 0 0 28px 10px;
  }

  .Mission-content h1 {
      font-size: 2em;
      font-weight: 200;
      /* margin-right: 25%; */
      border-bottom: solid 1px #C5B358;
  }

  .Mission-content h2 {
    font-size: 1.4em;
    font-weight: 200;
    /* margin-right: 25%; */
    /* border-bottom: solid 1px #C5B358; */
  }

  .Mission-content h3 {
    font-size: 1.5em;
    font-weight: 300;
    margin-bottom:0;
    /* margin-right: 25%; */
    /* border-bottom: solid 1px #C5B358; */
  }

  .Mission-content h4 {
    font-weight: 200;
    font-size:1.5em;
    margin:0;
  }

  .mern-image {
    height:50px
  }

  .Mission-image {
    width: 50%;
    -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    /* top: 200px; */
    background-image: url(/static/media/Eliott.a66929b7.png);
    background-repeat: no-repeat;
    background-position: center 24%;
    background-size: clamp(680px, 150%, 750px);
  }
  

  .release-card {
    width: 586px;
    height: 176px;
    background-color: #fdfdfd;
    padding: 25px 40px 25px 40px;
    margin-bottom: 3vh;
    box-shadow: 13px 13px 30px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .release-app-icon{
    width: 110px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
  }
  .title-and-type{
    
  }
  .release-card h2 {
    display: inline-block;
    font-weight: 400;
    text-align: left;
    font-size: 18px;
    margin-left: 2.5vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  .release-store-buttons-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 3vw
  }
  .release-store-buttons-wrapper img {
    width: 180px
  }
  .image-and-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width:290px;
  }

/* GRID */

@supports (display: grid) {

    .grid {
      margin: 0 3vw 0 2vw;
      display: grid;
      grid-gap: 1.5vw;
      gap: 1.5vw;
      min-height: 90vh;
      padding: 1.5vw;
      position: relative;
      /* top: -80px; */
    }
  
    /* .grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 50vh 30vh repeat(7, minmax(20vh, 1fr));
      grid-template-areas:
        "hero   hero"
        "big1   big1"
        "hero2 hero2"
        "hero3 hero3"
        "med1   med1"
        "med2   med2"
        "small1 small2"
        "small3 small4"
        "small5 small6"
        "small7 small8"
        "small9 small10";
    } */
  
    /*@media screen and (min-width: 750px) {
      .grid {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 25vh);
        grid-template-areas:
          "hero   hero   hero   med1   med1"
          "hero   hero   hero   med2   med2"
          "big1   big1   big1   big1   big1"
          "small1 small2 small3 small4 small5"
          "small6 small7 small8 small9 small10";
      }
    }*/
  
    /* @media screen and (min-width: 1000px) { */
      .grid {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "hero hero   hero hero med1   med1"
        "hero hero   hero hero med2   med2"
        "hero2 hero2   hero2 hero3   hero3 hero3"
      }
    /* } */
  
    .grid .item:nth-child(1)  { grid-area: hero; }
    .grid .item:nth-child(2)  { grid-area: med1; }
    .grid .item:nth-child(3)  { grid-area: hero2; }
    .grid .item:nth-child(4)  { grid-area: med2; }
    .grid .item:nth-child(5)  { grid-area: hero3; }
  
  }
  
  /* flexbox fallback is the browser does not support display:grid */
  @supports not (display: grid) {
  
    .grid {
      display: flex;
      flex-flow: row wrap;
      min-height: 100vh;
      padding: 0.75vw;
    }
  
    .grid .item {
      min-height: 20vh;
      margin: 0.75vw;
    }
  
    .grid .item:nth-child(1)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); height: 50vh; }
    .grid .item:nth-child(2)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); height: 30vh; }
    .grid .item:nth-child(3)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); }
    .grid .item:nth-child(4)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); }
    .grid .item:nth-child(5)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
    .grid .item:nth-child(6)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
    .grid .item:nth-child(7)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }

    /*@media screen and (min-width: 750px) {
      .grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); height: 50vh; }
    }*/
  
    @media screen and (min-width: 1400px) {
      .grid .item:nth-child(1)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(40% - 1.5vw); }
      .grid .item:nth-child(3)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
      .grid .item:nth-child(4)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
      .grid .item:nth-child(5)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
      .grid .item:nth-child(6)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
      .grid .item:nth-child(7)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
    }
  
  }
  
  /* Styles, just for fun */
  
  *,*:before,*:after {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: #29343d;
  } */
  
  @supports not (display: grid) {
    body:before {
      content: 'Sorry, seems like your browser doesn\'t support display: grid. Below is the flexbox fallback.';
      display: block;
      padding: 2rem 2rem 0;
      color: #ffffff;
      text-align: center;
    }
  }
  
  /* .header {
    margin: 10vh 1.5vw;
    text-align: center;
    color: #cedfe9;
  } */
  
  .header h1,
  .header h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    /* line-height: 1; */
  }
  
  .header h1 {
    position: relative;
    padding-bottom: 0.5em;
    color: #ffffff;
    font-size: 2rem;
  }
  
  .header h2 {
    font-weight: normal;
    font-size: 0.875rem;
  }
  
  @media screen and (min-width: 750px) {
  
    .header h1 {
      font-size: 3.75rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.125rem;
      letter-spacing: 0.5em;
    }
  }
  
  @media screen and (min-width: 1400px) {
  
    .header h1 {
      font-size: 6rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }
  
  .box {
    /* color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height: 100%;
    /* border: solid 1px #1d365c; */
    /* font-weight: 100; */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .box::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .box:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .box:hover::after {
    opacity: 1;
  }

  .box h1 {
      font-weight: 200;
  }

  .box span {
      font-weight: 400;
  }
  
  .diamond {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.2);
    background-image: linear-gradient(to top left, #ddd 0%, #fff 100%);
    text-align: center;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    transition: box-shadow 250ms, -webkit-transform 250ms;
    transition: box-shadow 250ms, transform 250ms;
    transition: box-shadow 250ms, transform 250ms, -webkit-transform 250ms;
  }
  
  .diamond span {
    font-weight: bold;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg)
  }
  
  .grid .item:hover .diamond,
  .grid .item:focus .diamond {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    -webkit-transform:  rotateZ(45deg) scale(1.2);
            transform:  rotateZ(45deg) scale(1.2);
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    background: #29343d;
    opacity: 0;
    color: #cedfe9;
    font-size: 0.875rem;
    transition: opacity 500ms, -webkit-transform 500ms;
    transition: opacity 500ms, transform 500ms;
    transition: opacity 500ms, transform 500ms, -webkit-transform 500ms;
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #29343d;
  }
  
  .grid .item:hover .tooltip,
  .grid .item:focus .tooltip {
    opacity: 1;
    -webkit-transform: translateY(70px);
            transform: translateY(70px);
  }
  
  .grid .item {
    position: relative;
    /* padding: 1vw; */
    background-position:  left bottom;
    background-size: 150% 100%;
    transition: background 500ms, outline 500ms;
    background-position: top left;
    outline: transparent;
    border-color: white;
  }
  
  .grid .item:hover,
  .grid .item:focus {
    background-position: top right;
  }
  
  .grid .item:focus {
    outline: 2px solid #3eabfa;
  }
  

/* LINES */
.box-lines {
  width:100%;
  height:100%;
  background-color:black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000000 0%, #cfbd5f  61%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
}

.box-lines h1 {
  position:absolute;
  color: #fff;
}
 .box-lines:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


/* AI */

.AI-box {
  /* position: absolute; */
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.AI-box span {
  text-decoration: none;
}

.particles-AI {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* MAIN BOX */

.main-box {
  width: 100%;
  height:100%;
  color: black;
  background-color: #fdfdfd;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-color: white;
  background-image: linear-gradient(to right, #ffffff 0%, #fdfdfd  51%, rgb(243, 243, 243)  100%);
}

.Features-home-top {
  margin-left: 5%;
  height: 15%;
  margin-bottom: 15px;
  padding: 0 15% 0 2%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: solid 1px #C5B358;
  font-weight: 400;
  display: flex;
  flex-direction: column-reverse
}

.features-home-explained {
  /* margin: 5% 2% 5% 2%; */
  padding-left: 5%;
  padding-right: 10%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-grow : 1; */
  justify-content: center;

}

.features-home-explained p {
  font-size: 20px;
  font-weight: 200;
  margin-right: 15%;
  margin-left: 10px;
}

.features-home-explained span{
color:#1d365c;
font-weight:300;
}

.features-home-explained h2{
  font-weight: 300;
  

}


.Enquire-top {
  width: 100%;
  margin-top: 15px;
  padding-right: 7%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}



.box-wave {
  width: 100%;
  height: 100%;
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  /* GRAD */
  background-image: linear-gradient(to right, #000000 0%, #1946c3dc  51%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   display: block;
}

 .box-wave:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

  

.box-wave-flex {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #fff;
}



/* BOX WEB */

.box-web {
  width: 100%;
  height: 100%;
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  /* GRAD */
  background-image: linear-gradient(to right, #000000 0%, #bd2b2b  51%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   display: block;
}

 .box-web:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

  

.box-web-flex {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* font-weight: 300; */
}



body {
    /* background-color: #1F1F1F; */
    background-color: #000000;
    font-family: "Roboto";
}

.Mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.Mobile-dev {
    position: absolute;
    z-index: 1;
}

.back-mobile {
    /* position: absolute; */
    /* height: 200%; */
    z-index: 0;
}

/* TITLE */

.title-mobile {
    margin-left: 10%;
    margin-top: 3%;
    font-size: 2.5em;
    font-weight: 100;
    color: white;
    letter-spacing: 2px;
}

.cursor {
    font-size: 0.75em;
    letter-spacing: 2px;
}

.title-line {
    position: relative;
    top: -15px;
    width:30%;
    margin-left:8%;
    border-top: solid 1px #C5B358;
}

/* TOP CARD */

/* .top-card{
    height: 100%;
} */

.top-subtitles {
    margin-top: 6%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subtitles {
    /* flex-direction: column;
    justify-content: space-around;
    width: 50vw; */
    text-align: left;
    line-height: 1.6;
    list-style: none;
    padding: 5% 5% 5% 15%;
    font-weight: 400;
    letter-spacing: 1.5px;
    /* padding: 0 0 8px 10px;
    display: inline; */
}

.logoSVG {
    padding: 0 22% 0 10%;
    width: 50vw;
}

.IconIcon {
    /* width: 50%; */
}

.question {
    font-size: 1.5em;
    justify-content: space-between;
}

.questions-gold {
    font-weight: 300;
    color: #C5B358;
}

.Enquire-top {
    width: 100%;
    padding-top: 1%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* FEATURES */

.features {
    background-color: #fafafa;
    box-shadow: 10px 10px 70px 10px rgba(255, 255, 255, 0.4);
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9% 5% 0 5%;
    border-radius: 10px;
}

.feature-table {
    width: 90%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.feature-card {
    /* width: 50%; */
    flex-direction: column;
    vertical-align: top;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* padding: 5% 20% 5% 20%; */
}

.feature-title {
    font-weight: 300;
}

.feature-content {
    padding: 0% 10% 0% 10%;
    line-height: 1.7em;
    font-size: 1.2em;
}

.feature-card a {
    color: #C5B358;
    font-weight: 500;
}

/* CONTACT */

.contact-card {
    padding: 2%;
}

.popup-content {
    border-radius: 6px;
    width: 90%;
    /* max-width: fit-content; */
}

.contact-us {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    /* color: white; */
    text-align: left;
    padding: 0 17% 1% 1%;
    margin: 0 0 6% 0;
    font-weight:100;
}

.button-send {
    text-align: right;
}

.contact-card > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 6px 8px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 28px;
    background: #ffffff;
    border-radius: 25px;
    border: 1px solid #cfcece;
    box-shadow: 0px 2px 10px 0px rgba(134, 134, 134, 0.356)
  }

/* BACKGROUND */

/* .ariere-mobile {
    position: relative;
    height: 70%;
  } */

  .circles{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 85%;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border-bottom: solid 1px #2f2f2f;
  }

  .circles li{
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.418);
      -webkit-animation: animate 21s linear infinite;
              animation: animate 21s linear infinite;
      bottom: -150px;
      
  }

  .circles li:nth-child(1){
      left: 25%;
      width: 80px;
      height: 80px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
  }


  .circles li:nth-child(2){
      left: 10%;
      width: 20px;
      height: 20px;
      -webkit-animation-delay: 0.5s;
              animation-delay: 0.5s;
      -webkit-animation-duration: 10s;
              animation-duration: 10s;
  }

  .circles li:nth-child(3){
      left: 70%;
      width: 20px;
      height: 20px;
      -webkit-animation-delay: 1.1s;
              animation-delay: 1.1s;
  }

  .circles li:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
      -webkit-animation-duration: 6s;
              animation-duration: 6s;
  }

  .circles li:nth-child(5){
      left: 65%;
      width: 20px;
      height: 20px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
  }

  .circles li:nth-child(6){
      left: 75%;
      width: 110px;
      height: 110px;
      -webkit-animation-delay: 1.8s;
              animation-delay: 1.8s;
  }

  .circles li:nth-child(7){
      left: 35%;
      width: 150px;
      height: 150px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
  }

  .circles li:nth-child(8){
      left: 50%;
      width: 25px;
      height: 25px;
      -webkit-animation-delay: 7s;
              animation-delay: 7s;
      -webkit-animation-duration: 20s;
              animation-duration: 20s;
  }

  .circles li:nth-child(9){
      left: 20%;
      width: 15px;
      height: 15px;
      -webkit-animation-delay: 1s;
              animation-delay: 1s;
      -webkit-animation-duration: 10s;
              animation-duration: 10s;
  }

  .circles li:nth-child(10){
      left: 85%;
      width: 150px;
      height: 150px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
      -webkit-animation-duration: 12s;
              animation-duration: 12s;
  }

  @-webkit-keyframes animate {

      0%{
          -webkit-transform: translateY(0) rotate(0deg);
                  transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }

      100%{
          -webkit-transform: translateY(-1000px) rotate(720deg);
                  transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }

  }

  @keyframes animate {

      0%{
          -webkit-transform: translateY(0) rotate(0deg);
                  transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
      }

      100%{
          -webkit-transform: translateY(-1000px) rotate(720deg);
                  transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
      }

  }

.drawer-menu {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    padding: 120px 0;
    background: #222;
    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: perspective(500px) rotateY(-90deg);
    transform: perspective(500px) rotateY(-90deg);
    opacity: 0;
}

.drawer-menu li {
    text-align: center;
}

.drawer-menu li a {
    display: block;
    height: 100px;
    line-height: 50px;
    font-size: 14px;
    color: #fff;
    transition: all .8s;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
}

.drawer-menu li a:hover {
    color: #1a1e24;
    background: #fff;
}

/* checkbox */
.check {
    display: none;
}

/* menu button - label tag */
.menu-btn {
    position: fixed;
    display: block;
    top: 40px;
    right: 40px;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    z-index: 3;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 1px;
    z-index: 4;
    background: #fff;
    mix-blend-mode: difference;
    transition: all .5s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
}

.bar:after {
    mix-blend-mode: difference;
    color: white;
}

.bar.middle {
    top: 15px;
    opacity: 1;
}

.bar.bottom {
    top: 30px;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

.menu-btn__text {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
    transition: all .5s;
    display: block;
    visibility: visible;
    opacity: 1;
}

.menu-btn:hover .bar {
    background: #999;
}

.menu-btn:hover .menu-btn__text {
    color: #999;
}

.close-menu {
    position: fixed;
    top: 0;
    right: 300px;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0);
    cursor: url(http://theorthodoxworks.com/demo/images/cross.svg),auto;
    transition-property: all;
    transition-duration: .3s;
    transition-delay: 0s;
    visibility: hidden;
    opacity: 0;
}

/* checked */
.check:checked ~ .drawer-menu {
    transition-delay: .3s;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    z-index: 2;
}

.check:checked ~ .contents {
    transition-delay: 0s;
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
}

.check:checked ~ .menu-btn .menu-btn__text {
    visibility: hidden;
    opacity: 0;
}

.check:checked ~ .menu-btn .bar.top {
    width: 56px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.check:checked ~ .menu-btn .bar.middle {
    opacity: 0;
}

.check:checked ~ .menu-btn .bar.bottom {
    width: 56px;
    top: 40px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.check:checked ~ .close-menu {
    transition-duration: 1s;
    transition-delay: .3s;
    background: rgba(0,0,0,.5);
    visibility: visible;
    opacity: 1;
    z-index: 3;
}
.footer {
  margin:2% ;
  color: rgb(189, 189, 189);
    /* position:absolute; */
    /* height: fit-content; */
    /* background-color: coral; */
    /* z-index: -1; */
    /* position: absolute; */
  }
  
  .company-info {
    
  }
/* IMPORTANT: USUES CSS FROM MOBILE.CSS */

#WebDevBanner {
}

#WebDev {
    background-color: white;
    margin: none;
    height: 100vh;
}

.top-subtitles {
  margin-top: 2%;
}

.features {
  margin-top: 7%;
}

/* CODE ANIMATION */

.feature-card {
    width:50%;
}





body {
    /* background-color: #1F1F1F; */
    background-color: #141414;
    font-family: "Roboto";
}

.mob-particles {
  z-index: -1;
  position: fixed; 
  left: -100px; 
}

.Top-home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mob-logo {
    margin: 20vh 0 0 0;
    width: 270px;
    /* color: #4b4b4b */
}

.mob-header {
    color: white;
    margin:100px 0 35vh 0;
    font-weight: 100;
    font-size: 30px;  
}

.business {
    color:#C5B358;
    /* font-weight: 300; */
    /* color: #424242 */
}

.solutions {
    width: 100%;
    box-shadow: inset 0 7px 60px -20px rgba(0,0,0,0.4);
    background-color: rgb(245, 245, 245);
    margin-top: 10px
}

.mob-features-home-explained {
  /* margin: 5% 2% 5% 2%; */
  padding-left: 15px;
  padding-right: 5px;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-grow : 1; */
  /* justify-content: center; */

}

.mob-features-home-explained-title {
    font-size: 10px;
    margin-left: 20px;
    height: 43px;
    margin-bottom: 0;
    padding: 0 20px 0 2px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    font-weight: 400;
    display: flex;

}


.mob-features-home-explained p {
  position:relative;
  font-size: 15px;
  font-weight: 200;
  margin-right: 15%;
  margin-left: 2px;
  margin-bottom:5px;
}

.mob-features-home-explained span{
color:#1d365c;
font-weight:300;
}

.mob-features-home-explained h2{
  font-weight: 300;
  font-size: 12px;
  margin:0;
  position: relative;
}


/* ARROW */

.ImgArrow {
    width: 150px;
}

.arrow Link {
    color: white; text-decoration: none;
    width: 20px;
}

.arrow {
    text-align: center;
    margin: 3% 0;
    color: white;
  }
  .bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px);
    }
  }

  /* MISSION */

  .mob-Mission {
      position: relative;
      top: -200px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin: 2% 13%;
      display: flex;
      flex-direction: row;
      background-color: #f3f3f3;
      padding: 0 15px 0 15px;
      border-radius: 3px;
      box-shadow: 12px 15px 30px rgba(0,0,0,0.2);
      /* border: solid black; */
  }

  .mob-Mission-content {
      flex-direction: column;
      justify-content: space-around;
      text-align: left;
      line-height: 1.6;
      padding: 0 10px 8px 10px;
  }

  .mob-Mission-content h1 {
      font-size: 1.5em;
      font-weight: 200;
      /* margin-right: 25%; */
      width: 80%;
      margin-bottom:0;
      /* border-bottom: solid 1px #C5B358; */
  }

  .mob-Mission-content h2 {
    font-size: 1.2em;
    font-weight: 200;
    /* margin-right: 25%; */
    width: 80%;
    margin-top:0;
    /* border-bottom: solid 1px #C5B358; */
} 
  
  .mob-Mission-content h3 {
    font-weight: 200;
    margin-bottom:0
}

  .mob-Mission-content h4 {
  font-weight: 200;
  margin:0
}

  .mob-Mission p {
      font-size: 15px;
      font-weight: 200;
      text-align: justify;
      /* margin-right: 15%; */
  }

  .mob-Mission p span{
    color:#1d365c;
    font-weight:300;
  }

  .image-eliott {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    /* top: 200px; */
    /* clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%); */
    background-image: url(/static/media/Eliott.a66929b7.png);
    background-repeat: no-repeat;
    background-position: 55% 16% ;
    background-size: clamp(100px, 200%, 750px);
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
  }

  .mob-release-card {
    background-color: #fdfdfd;
    padding: 15px 20px 15px 20px;
    box-shadow: 8px 12px 18px rgba(6, 6, 6, 0.15);
    margin-bottom: 3vh;
    height: 160px;
    width: 273px;
  }

/* GRID */

@supports (display: grid) {

    .mob-grid {
      margin: 0 3vw 0 2vw;
      display: grid;
      grid-gap: 1.5vw;
      gap: 1.5vw;
      min-height: 90vh;
      padding: 1.5vw;
      position: relative;
      top: -160px;
    }
  
    /* @media screen and (min-width: 1000px) { */
      .mob-grid {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "hero   hero hero hero   hero hero"
        "med2   med2 med2 med1   med1 med1"
        "hero3 hero3 hero3 hero3 hero3 hero3"
        "hero2 hero2 hero2 hero2 hero2 hero2"
        
      }
    /* } */
  
    .mob-grid .item:nth-child(1)  { grid-area: hero; }
    .mob-grid .item:nth-child(2)  { grid-area: med1; }
    .mob-grid .item:nth-child(3)  { grid-area: hero2; }
    .mob-grid .item:nth-child(4)  { grid-area: med2; }
    .mob-grid .item:nth-child(5)  { grid-area: hero3; }
  
  }
  
  /* flexbox fallback is the browser does not support display:grid */
  @supports not (display: grid) {
  
    .mob-grid {
      display: flex;
      flex-flow: row wrap;
      min-height: 100vh;
      padding: 0.75vw;
    }
  
    .mob-grid .item {
      min-height: 20vh;
      margin: 0.75vw;
    }
  
    .mob-grid .item:nth-child(1)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); height: 50vh; }
    .mob-grid .item:nth-child(2)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); height: 30vh; }
    .mob-grid .item:nth-child(3)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); }
    .mob-grid .item:nth-child(4)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(100% - 1.5vw); }
    .mob-grid .item:nth-child(5)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
    .mob-grid .item:nth-child(6)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
    .mob-grid .item:nth-child(7)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }

    /*@media screen and (min-width: 750px) {
      .grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); height: 50vh; }
    }*/
  
    @media screen and (min-width: 1400px) {
      .mob-grid .item:nth-child(1)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(60% - 1.5vw); }
      .mob-grid .item:nth-child(2)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(40% - 1.5vw); }
      .mob-grid .item:nth-child(3)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
      .mob-grid .item:nth-child(4)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(50% - 1.5vw); }
      .mob-grid .item:nth-child(5)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
      .mob-grid .item:nth-child(6)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
      .mob-grid .item:nth-child(7)  { flex-grow: 0; flex-shrink: 1; flex-basis: calc(25% - 1.5vw); }
    }
  
  }
  
  /* Styles, just for fun */
  
  *,*:before,*:after {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: #29343d;
  } */
  
  @supports not (display: grid) {
    body:before {
      content: 'Sorry, seems like your browser doesn\'t support display: grid. Below is the flexbox fallback.';
      display: block;
      padding: 2rem 2rem 0;
      color: #ffffff;
      text-align: center;
    }
  }
  
  /* .header {
    margin: 10vh 1.5vw;
    text-align: center;
    color: #cedfe9;
  } */
  
  .header h1,
  .header h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1;
  }
  
  .header h1 {
    position: relative;
    padding-bottom: 0.5em;
    color: #ffffff;
    font-size: 2rem;
  }
  
  .header h2 {
    font-weight: normal;
    font-size: 0.875rem;
  }
  
  @media screen and (min-width: 750px) {
  
    .header h1 {
      font-size: 3.75rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.125rem;
      letter-spacing: 0.5em;
    }
  }
  
  @media screen and (min-width: 1400px) {
  
    .header h1 {
      font-size: 6rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  .mob-box {
    position:relative;
    margin:2% 13%;
    top:-150px;
    /* color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    /* height: 100%; */
    /* border: solid 1px #1d365c; */
    /* font-weight: 100; */
    box-shadow: 13px 13px 30px rgba(0,0,0,0.3);
    border-radius: 4px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .mob-main-box {
    position:"relative";
    width: 100%;
    height:110%;
    color: black;
    background-color: #fdfdfd;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-color: white;
    background-image: linear-gradient(to right, #ffffff 0%, #fdfdfd  51%, rgb(243, 243, 243)  100%);
  }

  .mob-box::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .mob-box:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .mob-box:hover::after {
    opacity: 1;
  }

  .mob-box h1 {
      font-weight: 200;
  }

  .mob-box span {
      font-weight: 400;
  }
  
  .diamond {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.2);
    background-image: linear-gradient(to top left, #ddd 0%, #fff 100%);
    text-align: center;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    transition: box-shadow 250ms, -webkit-transform 250ms;
    transition: box-shadow 250ms, transform 250ms;
    transition: box-shadow 250ms, transform 250ms, -webkit-transform 250ms;
  }
  
  .diamond span {
    font-weight: bold;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg)
  }
  
  .grid .item:hover .diamond,
  .grid .item:focus .diamond {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    -webkit-transform:  rotateZ(45deg) scale(1.2);
            transform:  rotateZ(45deg) scale(1.2);
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    background: #29343d;
    opacity: 0;
    color: #cedfe9;
    font-size: 0.875rem;
    transition: opacity 500ms, -webkit-transform 500ms;
    transition: opacity 500ms, transform 500ms;
    transition: opacity 500ms, transform 500ms, -webkit-transform 500ms;
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #29343d;
  }
  
  .grid .item:hover .tooltip,
  .grid .item:focus .tooltip {
    opacity: 1;
    -webkit-transform: translateY(70px);
            transform: translateY(70px);
  }
  
  .grid .item {
    position: relative;
    /* padding: 1vw; */
    background-position:  left bottom;
    background-size: 150% 100%;
    transition: background 500ms, outline 500ms;
    background-position: top left;
    outline: transparent;
    border-color: white;
  }
  
  .grid .item:hover,
  .grid .item:focus {
    background-position: top right;
  }
  
  .grid .item:focus {
    outline: 2px solid #3eabfa;
  }



/* LINES */
.mob-box-lines {
  width:100%;
  height:100%;
  background-color:black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000000 0%, #cfbd5f  61%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   text-decoration: none;
}

.box-lines h1 {
  position:absolute;
  color: #fff;
}
 .mob-box-lines:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


/* AI */

.AI-box {
  /* position: absolute; */
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.AI-box span {
  text-decoration: none;
}

.mob-particles-AI {
  /* z-index: 2; */
  position: absolute;
  width: 100%;
  height: 100%;
}


body {
    /* background-color: #1F1F1F; */
    background-color: #000000;
    font-family: "Roboto";
}

.Mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.Mobile-dev {
    position: absolute;
    z-index: 1;
}

.back-mobile {
    /* position: absolute; */
    /* height: 200%; */
    z-index: 0;
}

/* TITLE */

.title-mobile {
    margin-left: 10%;
    margin-top: 3%;
    font-size: 2.5em;
    font-weight: 100;
    color: white;
    letter-spacing: 2px;
}

.cursor {
    font-size: 0.75em;
    letter-spacing: 2px;
}

.title-line {
    position: relative;
    top: -15px;
    width:30%;
    margin-left:8%;
    border-top: solid 1px #C5B358;
}

/* TOP CARD */

/* .top-card{
    height: 100%;
} */

.top-subtitles {
    margin-top: 6%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subtitles {
    /* flex-direction: column;
    justify-content: space-around;
    width: 50vw; */
    text-align: left;
    line-height: 1.6;
    list-style: none;
    padding: 5% 5% 5% 15%;
    font-weight: 400;
    letter-spacing: 1.5px;
    /* padding: 0 0 8px 10px;
    display: inline; */
}

.mob-logoSVG {

}

.IconIcon {
    /* width: 50%; */
}

.question {
    font-size: 1.5em;
    justify-content: space-between;
}

.questions-gold {
    font-weight: 300;
    color: #C5B358;
}

.Enquire-top {
    width: 100%;
    padding-top: 1%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* FEATURES */

.mob-features {
    background-color: #ffffff1f;
    /* box-shadow: 10px 10px 70px 10px rgba(255, 255, 255, 0.4); */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9% 5% 5% 5%;
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.685);
}

.feature-card {
    /* width: 50%; */
    flex-direction: column;
    vertical-align: top;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* padding: 5% 20% 5% 20%; */
}

.feature-title {
    font-weight: 300;
}

.mob-feature-content {
    font-weight: 300;
    padding: 0% 10% 0% 10%;
    line-height: 1.7em;
    font-size: 1.2em;
}

.mob-feature-card a {
    color: #C5B358;
    /* font-weight: 500; */
}

/* CONTACT */

.contact-card {
    padding: 2%;
}

.popup-content {
    border-radius: 6px;
    width: 90%;
    /* max-width: fit-content; */
}

.contact-us {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    /* color: white; */
    text-align: left;
    padding: 0 17% 1% 1%;
    margin: 0 0 6% 0;
    font-weight:100;
}

.button-send {
    text-align: right;
}

.contact-card > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 6px 8px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 28px;
    background: #ffffff;
    border-radius: 25px;
    border: 1px solid #cfcece;
    box-shadow: 0px 2px 10px 0px rgba(134, 134, 134, 0.356)
  }


.mob-footer {
  margin:2% ;
  margin-top: 5%;
  font-size: 8px;
  color: rgb(189, 189, 189);
  padding: 3%;
  border-top: solid 1px rgba(189, 189, 189, 0.452);
  }
 
/* IMPORTANT: USUES CSS FROM MOBILE.CSS */

#WebDevBanner {
}

#WebDev {
    background-color: white;
    margin: none;
    height: 100vh;
}

.top-subtitles {
  margin-top: 2%;
}

.features {
  margin-top: 7%;
}

/* CODE ANIMATION */


.feature-card {
    width:50%;
}





.privacy {
    color: rgb(224, 224, 224);
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px
}

.privacy-privacy {
    max-width: 1000px;
}

.privacy-content {
    font-size: 18px;
}
