body {
    /* background-color: #1F1F1F; */
    background-color: #141414;
    font-family: "Roboto";
}

.mob-particles {
  z-index: -1;
  position: fixed; 
  left: -100px; 
}

.Top-home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mob-logo {
    margin: 20vh 0 0 0;
    width: 270px;
    /* color: #4b4b4b */
}

.mob-header {
    color: white;
    margin:100px 0 35vh 0;
    font-weight: 100;
    font-size: 30px;  
}

.business {
    color:#C5B358;
    /* font-weight: 300; */
    /* color: #424242 */
}

.solutions {
    width: 100%;
    box-shadow: inset 0 7px 60px -20px rgba(0,0,0,0.4);
    background-color: rgb(245, 245, 245);
    margin-top: 10px
}

.mob-features-home-explained {
  /* margin: 5% 2% 5% 2%; */
  padding-left: 15px;
  padding-right: 5px;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-grow : 1; */
  /* justify-content: center; */

}

.mob-features-home-explained-title {
    font-size: 10px;
    margin-left: 20px;
    height: 43px;
    margin-bottom: 0;
    padding: 0 20px 0 2px;
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    font-weight: 400;
    display: flex;

}


.mob-features-home-explained p {
  position:relative;
  font-size: 15px;
  font-weight: 200;
  margin-right: 15%;
  margin-left: 2px;
  margin-bottom:5px;
}

.mob-features-home-explained span{
color:#1d365c;
font-weight:300;
}

.mob-features-home-explained h2{
  font-weight: 300;
  font-size: 12px;
  margin:0;
  position: relative;
}


/* ARROW */

.ImgArrow {
    width: 150px;
}

.arrow Link {
    color: white; text-decoration: none;
    width: 20px;
}

.arrow {
    text-align: center;
    margin: 3% 0;
    color: white;
  }
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  /* MISSION */

  .mob-Mission {
      position: relative;
      top: -200px;
      height: fit-content;
      margin: 2% 13%;
      display: flex;
      flex-direction: row;
      background-color: #f3f3f3;
      padding: 0 15px 0 15px;
      border-radius: 3px;
      box-shadow: 12px 15px 30px rgba(0,0,0,0.2);
      /* border: solid black; */
  }

  .mob-Mission-content {
      flex-direction: column;
      justify-content: space-around;
      text-align: left;
      line-height: 1.6;
      padding: 0 10px 8px 10px;
  }

  .mob-Mission-content h1 {
      font-size: 1.5em;
      font-weight: 200;
      /* margin-right: 25%; */
      width: 80%;
      margin-bottom:0;
      /* border-bottom: solid 1px #C5B358; */
  }

  .mob-Mission-content h2 {
    font-size: 1.2em;
    font-weight: 200;
    /* margin-right: 25%; */
    width: 80%;
    margin-top:0;
    /* border-bottom: solid 1px #C5B358; */
} 
  
  .mob-Mission-content h3 {
    font-weight: 200;
    margin-bottom:0
}

  .mob-Mission-content h4 {
  font-weight: 200;
  margin:0
}

  .mob-Mission p {
      font-size: 15px;
      font-weight: 200;
      text-align: justify;
      /* margin-right: 15%; */
  }

  .mob-Mission p span{
    color:#1d365c;
    font-weight:300;
  }

  .image-eliott {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    /* top: 200px; */
    /* clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%); */
    background-image: url("../../assets/Eliott.png");
    background-repeat: no-repeat;
    background-position: 55% 16% ;
    background-size: clamp(100px, 200%, 750px);
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
  }

  .mob-release-card {
    background-color: #fdfdfd;
    padding: 15px 20px 15px 20px;
    box-shadow: 8px 12px 18px rgba(6, 6, 6, 0.15);
    margin-bottom: 3vh;
    height: 160px;
    width: 273px;
  }

/* GRID */

@supports (display: grid) {

    .mob-grid {
      margin: 0 3vw 0 2vw;
      display: grid;
      gap: 1.5vw;
      min-height: 90vh;
      padding: 1.5vw;
      position: relative;
      top: -160px;
    }
  
    /* @media screen and (min-width: 1000px) { */
      .mob-grid {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "hero   hero hero hero   hero hero"
        "med2   med2 med2 med1   med1 med1"
        "hero3 hero3 hero3 hero3 hero3 hero3"
        "hero2 hero2 hero2 hero2 hero2 hero2"
        
      }
    /* } */
  
    .mob-grid .item:nth-child(1)  { grid-area: hero; }
    .mob-grid .item:nth-child(2)  { grid-area: med1; }
    .mob-grid .item:nth-child(3)  { grid-area: hero2; }
    .mob-grid .item:nth-child(4)  { grid-area: med2; }
    .mob-grid .item:nth-child(5)  { grid-area: hero3; }
  
  }
  
  /* flexbox fallback is the browser does not support display:grid */
  @supports not (display: grid) {
  
    .mob-grid {
      display: flex;
      flex-flow: row wrap;
      min-height: 100vh;
      padding: 0.75vw;
    }
  
    .mob-grid .item {
      min-height: 20vh;
      margin: 0.75vw;
    }
  
    .mob-grid .item:nth-child(1)  { flex: 0 1 calc(100% - 1.5vw); height: 50vh; }
    .mob-grid .item:nth-child(2)  { flex: 0 1 calc(100% - 1.5vw); height: 30vh; }
    .mob-grid .item:nth-child(3)  { flex: 0 1 calc(100% - 1.5vw); }
    .mob-grid .item:nth-child(4)  { flex: 0 1 calc(100% - 1.5vw); }
    .mob-grid .item:nth-child(5)  { flex: 0 1 calc(50% - 1.5vw); }
    .mob-grid .item:nth-child(6)  { flex: 0 1 calc(50% - 1.5vw); }
    .mob-grid .item:nth-child(7)  { flex: 0 1 calc(50% - 1.5vw); }

    /*@media screen and (min-width: 750px) {
      .grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); height: 50vh; }
    }*/
  
    @media screen and (min-width: 1400px) {
      .mob-grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .mob-grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); }
      .mob-grid .item:nth-child(3)  { flex: 0 1 calc(50% - 1.5vw); }
      .mob-grid .item:nth-child(4)  { flex: 0 1 calc(50% - 1.5vw); }
      .mob-grid .item:nth-child(5)  { flex: 0 1 calc(25% - 1.5vw); }
      .mob-grid .item:nth-child(6)  { flex: 0 1 calc(25% - 1.5vw); }
      .mob-grid .item:nth-child(7)  { flex: 0 1 calc(25% - 1.5vw); }
    }
  
  }
  
  /* Styles, just for fun */
  
  *,*:before,*:after {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: #29343d;
  } */
  
  @supports not (display: grid) {
    body:before {
      content: 'Sorry, seems like your browser doesn\'t support display: grid. Below is the flexbox fallback.';
      display: block;
      padding: 2rem 2rem 0;
      color: #ffffff;
      text-align: center;
    }
  }
  
  /* .header {
    margin: 10vh 1.5vw;
    text-align: center;
    color: #cedfe9;
  } */
  
  .header h1,
  .header h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1;
  }
  
  .header h1 {
    position: relative;
    padding-bottom: 0.5em;
    color: #ffffff;
    font-size: 2rem;
  }
  
  .header h2 {
    font-weight: normal;
    font-size: 0.875rem;
  }
  
  @media screen and (min-width: 750px) {
  
    .header h1 {
      font-size: 3.75rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.125rem;
      letter-spacing: 0.5em;
    }
  }
  
  @media screen and (min-width: 1400px) {
  
    .header h1 {
      font-size: 6rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  .mob-box {
    position:relative;
    margin:2% 13%;
    top:-150px;
    /* color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    /* height: 100%; */
    /* border: solid 1px #1d365c; */
    /* font-weight: 100; */
    box-shadow: 13px 13px 30px rgba(0,0,0,0.3);
    border-radius: 4px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .mob-main-box {
    position:"relative";
    width: 100%;
    height:110%;
    color: black;
    background-color: #fdfdfd;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-color: white;
    background-image: linear-gradient(to right, #ffffff 0%, #fdfdfd  51%, rgb(243, 243, 243)  100%);
  }

  .mob-box::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .mob-box:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .mob-box:hover::after {
    opacity: 1;
  }

  .mob-box h1 {
      font-weight: 200;
  }

  .mob-box span {
      font-weight: 400;
  }
  
  .diamond {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.2);
    background-image: linear-gradient(to top left, #ddd 0%, #fff 100%);
    text-align: center;
    transform-origin: center;
    transform: rotateZ(45deg);
    transition: box-shadow 250ms, transform 250ms;
  }
  
  .diamond span {
    font-weight: bold;
    transform: rotateZ(-45deg)
  }
  
  .grid .item:hover .diamond,
  .grid .item:focus .diamond {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    transform:  rotateZ(45deg) scale(1.2);
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    background: #29343d;
    opacity: 0;
    color: #cedfe9;
    font-size: 0.875rem;
    transition: opacity 500ms, transform 500ms;
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #29343d;
  }
  
  .grid .item:hover .tooltip,
  .grid .item:focus .tooltip {
    opacity: 1;
    transform: translateY(70px);
  }
  
  .grid .item {
    position: relative;
    /* padding: 1vw; */
    background-position:  left bottom;
    background-size: 150% 100%;
    transition: background 500ms, outline 500ms;
    background-position: top left;
    outline: transparent;
    border-color: white;
  }
  
  .grid .item:hover,
  .grid .item:focus {
    background-position: top right;
  }
  
  .grid .item:focus {
    outline: 2px solid #3eabfa;
  }



/* LINES */
.mob-box-lines {
  width:100%;
  height:100%;
  background-color:black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000000 0%, #cfbd5f  61%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   text-decoration: none;
}

.box-lines h1 {
  position:absolute;
  color: #fff;
}
 .mob-box-lines:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


/* AI */

.AI-box {
  /* position: absolute; */
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.AI-box span {
  text-decoration: none;
}

.mob-particles-AI {
  /* z-index: 2; */
  position: absolute;
  width: 100%;
  height: 100%;
}

