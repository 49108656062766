body {
    /* background-color: #1F1F1F; */
    background-color: #000000;
    font-family: "Roboto";
}

.Mobile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.Mobile-dev {
    position: absolute;
    z-index: 1;
}

.back-mobile {
    /* position: absolute; */
    /* height: 200%; */
    z-index: 0;
}

/* TITLE */

.title-mobile {
    margin-left: 10%;
    margin-top: 3%;
    font-size: 2.5em;
    font-weight: 100;
    color: white;
    letter-spacing: 2px;
}

.cursor {
    font-size: 0.75em;
    letter-spacing: 2px;
}

.title-line {
    position: relative;
    top: -15px;
    width:30%;
    margin-left:8%;
    border-top: solid 1px #C5B358;
}

/* TOP CARD */

/* .top-card{
    height: 100%;
} */

.top-subtitles {
    margin-top: 6%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subtitles {
    /* flex-direction: column;
    justify-content: space-around;
    width: 50vw; */
    text-align: left;
    line-height: 1.6;
    list-style: none;
    padding: 5% 5% 5% 15%;
    font-weight: 400;
    letter-spacing: 1.5px;
    /* padding: 0 0 8px 10px;
    display: inline; */
}

.mob-logoSVG {

}

.IconIcon {
    /* width: 50%; */
}

.question {
    font-size: 1.5em;
    justify-content: space-between;
}

.questions-gold {
    font-weight: 300;
    color: #C5B358;
}

.Enquire-top {
    width: 100%;
    padding-top: 1%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* FEATURES */

.mob-features {
    background-color: #ffffff1f;
    /* box-shadow: 10px 10px 70px 10px rgba(255, 255, 255, 0.4); */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 9% 5% 5% 5%;
    border-radius: 10px;
    border: solid 1px rgba(255, 255, 255, 0.685);
}

.feature-card {
    /* width: 50%; */
    flex-direction: column;
    vertical-align: top;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* padding: 5% 20% 5% 20%; */
}

.feature-title {
    font-weight: 300;
}

.mob-feature-content {
    font-weight: 300;
    padding: 0% 10% 0% 10%;
    line-height: 1.7em;
    font-size: 1.2em;
}

.mob-feature-card a {
    color: #C5B358;
    /* font-weight: 500; */
}

/* CONTACT */

.contact-card {
    padding: 2%;
}

.popup-content {
    border-radius: 6px;
    width: 90%;
    /* max-width: fit-content; */
}

.contact-us {
    width: fit-content;
    border-bottom: solid 1px #C5B358;
    /* color: white; */
    text-align: left;
    padding: 0 17% 1% 1%;
    margin: 0 0 6% 0;
    font-weight:100;
}

.button-send {
    text-align: right;
}

.contact-card > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 6px 8px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 28px;
    background: #ffffff;
    border-radius: 25px;
    border: 1px solid #cfcece;
    box-shadow: 0px 2px 10px 0px rgba(134, 134, 134, 0.356)
  }
