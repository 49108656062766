/* IMPORTANT: USUES CSS FROM MOBILE.CSS */

#WebDevBanner {
}

#WebDev {
    background-color: white;
    margin: none;
    height: 100vh;
}

.top-subtitles {
  margin-top: 2%;
}

.features {
  margin-top: 7%;
}

/* CODE ANIMATION */

