.privacy {
    color: rgb(224, 224, 224);
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px
}

.privacy-privacy {
    max-width: 1000px;
}

.privacy-content {
    font-size: 18px;
}