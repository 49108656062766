.footer {
  margin:2% ;
  color: rgb(189, 189, 189);
    /* position:absolute; */
    /* height: fit-content; */
    /* background-color: coral; */
    /* z-index: -1; */
    /* position: absolute; */
  }
  
  .company-info {
    
  }