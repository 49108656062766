body {
    /* background-color: #1F1F1F; */
    background-color: #141414;
    font-family: "Roboto";
}

.particles {
  /* position: absolute; */
  /* left: -100px; */
  /* width: 100%;
  height: 90%; */
  z-index: -1;
}

.Top-home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    margin: 17vh 0 0 0;
    width: 300px;
}

.header {
    color: white;
    margin:100px 0 31vh 0;
    font-weight: 100;
    font-size: 40px;  
}

.business {
    color:#C5B358;
    /* font-weight: 300; */
    /* color: #424242 */
}

.solutions {
    width: 100%;
    box-shadow: inset 0 7px 60px -20px rgba(0,0,0,0.4);
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
}


/* ARROW */

.ImgArrow {
    width: 150px;
}

.arrow Link {
    color: white; text-decoration: none;
    width: 20px;
}

.arrow {
    text-align: center;
    margin: 3% 0;
    color: white;
  }
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  /* MISSION */

  .Mission {
      position: relative;
      top: -200px;
      height: fit-content;
      margin: 2% 13%;
      display: flex;
      flex-direction: row;
      background-color: #fdfdfd;
      padding: 0 0 0 20px;
      border-radius: 2px;
      box-shadow: 15px 15px 35px rgba(0,0,0,0.4);
      /* border: solid black; */
  }

  .Mission-content {
      flex-direction: column;
      /* justify-content: space-around; */
      width: 50%;
      text-align: left;
      /* line-height: 1.6; */
      padding: 0 0 28px 10px;
  }

  .Mission-content h1 {
      font-size: 2em;
      font-weight: 200;
      /* margin-right: 25%; */
      border-bottom: solid 1px #C5B358;
  }

  .Mission-content h2 {
    font-size: 1.4em;
    font-weight: 200;
    /* margin-right: 25%; */
    /* border-bottom: solid 1px #C5B358; */
  }

  .Mission-content h3 {
    font-size: 1.5em;
    font-weight: 300;
    margin-bottom:0;
    /* margin-right: 25%; */
    /* border-bottom: solid 1px #C5B358; */
  }

  .Mission-content h4 {
    font-weight: 200;
    font-size:1.5em;
    margin:0;
  }

  .mern-image {
    height:50px
  }

  .Mission-image {
    width: 50%;
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    /* top: 200px; */
    background-image: url("../assets/Eliott.png");
    background-repeat: no-repeat;
    background-position: center 24%;
    background-size: clamp(680px, 150%, 750px);
  }
  

  .release-card {
    width: 586px;
    height: 176px;
    background-color: #fdfdfd;
    padding: 25px 40px 25px 40px;
    margin-bottom: 3vh;
    box-shadow: 13px 13px 30px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .release-app-icon{
    width: 110px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
  }
  .title-and-type{
    
  }
  .release-card h2 {
    display: inline-block;
    font-weight: 400;
    text-align: left;
    font-size: 18px;
    margin-left: 2.5vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  .release-store-buttons-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 3vw
  }
  .release-store-buttons-wrapper img {
    width: 180px
  }
  .image-and-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width:290px;
  }

/* GRID */

@supports (display: grid) {

    .grid {
      margin: 0 3vw 0 2vw;
      display: grid;
      gap: 1.5vw;
      min-height: 90vh;
      padding: 1.5vw;
      position: relative;
      /* top: -80px; */
    }
  
    /* .grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 50vh 30vh repeat(7, minmax(20vh, 1fr));
      grid-template-areas:
        "hero   hero"
        "big1   big1"
        "hero2 hero2"
        "hero3 hero3"
        "med1   med1"
        "med2   med2"
        "small1 small2"
        "small3 small4"
        "small5 small6"
        "small7 small8"
        "small9 small10";
    } */
  
    /*@media screen and (min-width: 750px) {
      .grid {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 25vh);
        grid-template-areas:
          "hero   hero   hero   med1   med1"
          "hero   hero   hero   med2   med2"
          "big1   big1   big1   big1   big1"
          "small1 small2 small3 small4 small5"
          "small6 small7 small8 small9 small10";
      }
    }*/
  
    /* @media screen and (min-width: 1000px) { */
      .grid {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "hero hero   hero hero med1   med1"
        "hero hero   hero hero med2   med2"
        "hero2 hero2   hero2 hero3   hero3 hero3"
      }
    /* } */
  
    .grid .item:nth-child(1)  { grid-area: hero; }
    .grid .item:nth-child(2)  { grid-area: med1; }
    .grid .item:nth-child(3)  { grid-area: hero2; }
    .grid .item:nth-child(4)  { grid-area: med2; }
    .grid .item:nth-child(5)  { grid-area: hero3; }
  
  }
  
  /* flexbox fallback is the browser does not support display:grid */
  @supports not (display: grid) {
  
    .grid {
      display: flex;
      flex-flow: row wrap;
      min-height: 100vh;
      padding: 0.75vw;
    }
  
    .grid .item {
      min-height: 20vh;
      margin: 0.75vw;
    }
  
    .grid .item:nth-child(1)  { flex: 0 1 calc(100% - 1.5vw); height: 50vh; }
    .grid .item:nth-child(2)  { flex: 0 1 calc(100% - 1.5vw); height: 30vh; }
    .grid .item:nth-child(3)  { flex: 0 1 calc(100% - 1.5vw); }
    .grid .item:nth-child(4)  { flex: 0 1 calc(100% - 1.5vw); }
    .grid .item:nth-child(5)  { flex: 0 1 calc(50% - 1.5vw); }
    .grid .item:nth-child(6)  { flex: 0 1 calc(50% - 1.5vw); }
    .grid .item:nth-child(7)  { flex: 0 1 calc(50% - 1.5vw); }

    /*@media screen and (min-width: 750px) {
      .grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); height: 50vh; }
    }*/
  
    @media screen and (min-width: 1400px) {
      .grid .item:nth-child(1)  { flex: 0 1 calc(60% - 1.5vw); }
      .grid .item:nth-child(2)  { flex: 0 1 calc(40% - 1.5vw); }
      .grid .item:nth-child(3)  { flex: 0 1 calc(50% - 1.5vw); }
      .grid .item:nth-child(4)  { flex: 0 1 calc(50% - 1.5vw); }
      .grid .item:nth-child(5)  { flex: 0 1 calc(25% - 1.5vw); }
      .grid .item:nth-child(6)  { flex: 0 1 calc(25% - 1.5vw); }
      .grid .item:nth-child(7)  { flex: 0 1 calc(25% - 1.5vw); }
    }
  
  }
  
  /* Styles, just for fun */
  
  *,*:before,*:after {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: #29343d;
  } */
  
  @supports not (display: grid) {
    body:before {
      content: 'Sorry, seems like your browser doesn\'t support display: grid. Below is the flexbox fallback.';
      display: block;
      padding: 2rem 2rem 0;
      color: #ffffff;
      text-align: center;
    }
  }
  
  /* .header {
    margin: 10vh 1.5vw;
    text-align: center;
    color: #cedfe9;
  } */
  
  .header h1,
  .header h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    /* line-height: 1; */
  }
  
  .header h1 {
    position: relative;
    padding-bottom: 0.5em;
    color: #ffffff;
    font-size: 2rem;
  }
  
  .header h2 {
    font-weight: normal;
    font-size: 0.875rem;
  }
  
  @media screen and (min-width: 750px) {
  
    .header h1 {
      font-size: 3.75rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.125rem;
      letter-spacing: 0.5em;
    }
  }
  
  @media screen and (min-width: 1400px) {
  
    .header h1 {
      font-size: 6rem;
    }
  
    .header h2 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }
  
  .box {
    /* color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    height: 100%;
    /* border: solid 1px #1d365c; */
    /* font-weight: 100; */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .box::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .box:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
  }

  .box:hover::after {
    opacity: 1;
  }

  .box h1 {
      font-weight: 200;
  }

  .box span {
      font-weight: 400;
  }
  
  .diamond {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ffffff;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.2);
    background-image: linear-gradient(to top left, #ddd 0%, #fff 100%);
    text-align: center;
    transform-origin: center;
    transform: rotateZ(45deg);
    transition: box-shadow 250ms, transform 250ms;
  }
  
  .diamond span {
    font-weight: bold;
    transform: rotateZ(-45deg)
  }
  
  .grid .item:hover .diamond,
  .grid .item:focus .diamond {
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    transform:  rotateZ(45deg) scale(1.2);
  }
  
  .tooltip {
    position: absolute;
    z-index: 1;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    background: #29343d;
    opacity: 0;
    color: #cedfe9;
    font-size: 0.875rem;
    transition: opacity 500ms, transform 500ms;
  }
  
  .tooltip:before {
    content: '';
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #29343d;
  }
  
  .grid .item:hover .tooltip,
  .grid .item:focus .tooltip {
    opacity: 1;
    transform: translateY(70px);
  }
  
  .grid .item {
    position: relative;
    /* padding: 1vw; */
    background-position:  left bottom;
    background-size: 150% 100%;
    transition: background 500ms, outline 500ms;
    background-position: top left;
    outline: transparent;
    border-color: white;
  }
  
  .grid .item:hover,
  .grid .item:focus {
    background-position: top right;
  }
  
  .grid .item:focus {
    outline: 2px solid #3eabfa;
  }
  

/* LINES */
.box-lines {
  width:100%;
  height:100%;
  background-color:black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000000 0%, #cfbd5f  61%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
}

.box-lines h1 {
  position:absolute;
  color: #fff;
}
 .box-lines:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


/* AI */

.AI-box {
  /* position: absolute; */
  color: white;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.AI-box span {
  text-decoration: none;
}

.particles-AI {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* MAIN BOX */

.main-box {
  width: 100%;
  height:100%;
  color: black;
  background-color: #fdfdfd;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-color: white;
  background-image: linear-gradient(to right, #ffffff 0%, #fdfdfd  51%, rgb(243, 243, 243)  100%);
}

.Features-home-top {
  margin-left: 5%;
  height: 15%;
  margin-bottom: 15px;
  padding: 0 15% 0 2%;
  width: fit-content;
  border-bottom: solid 1px #C5B358;
  font-weight: 400;
  display: flex;
  flex-direction: column-reverse
}

.features-home-explained {
  /* margin: 5% 2% 5% 2%; */
  padding-left: 5%;
  padding-right: 10%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-grow : 1; */
  justify-content: center;

}

.features-home-explained p {
  font-size: 20px;
  font-weight: 200;
  margin-right: 15%;
  margin-left: 10px;
}

.features-home-explained span{
color:#1d365c;
font-weight:300;
}

.features-home-explained h2{
  font-weight: 300;
  

}


.Enquire-top {
  width: 100%;
  margin-top: 15px;
  padding-right: 7%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}



.box-wave {
  width: 100%;
  height: 100%;
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  /* GRAD */
  background-image: linear-gradient(to right, #000000 0%, #1946c3dc  51%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   display: block;
}

 .box-wave:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

  

.box-wave-flex {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #fff;
}



/* BOX WEB */

.box-web {
  width: 100%;
  height: 100%;
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  /* GRAD */
  background-image: linear-gradient(to right, #000000 0%, #bd2b2b  51%, #000000  100%);
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   display: block;
}

 .box-web:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

  

.box-web-flex {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* font-weight: 300; */
}


